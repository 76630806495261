<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="anasalekanban">
        <p class="anasalekanban_tit">角色规则</p>
        <p class="anasalekanban_baocun">
            单位：元
            <span @click="dianji_baocun">保存</span>
        </p>
        <!-- :cell-style="cellStyle_tiao"
            :header-cell-style="headerCellStyle" -->
        <div class="table_con_ccc">
            <el-table :data="neirong_list"
                border
                :cell-style="liebiao_yangshi"
                :header-cell-style="biaotou_yangshi"
                height="4.4rem"
                >
                <el-table-column v-for="(i, ind) in biaotou"
                    :key="ind"
                    :prop="i.con"
                    :label="i.name">
                    <template slot-scope="props">
                        <span v-if="i.con=='label'"><span v-if="props.row.bitian" :style="props.row.bitian?'color:red;':''">*</span>{{props.row[i.con]}}</span>
                        <input v-model="props.row[i.con]" @change="(props.row.label.indexOf('率')!='-1'||props.row.label.indexOf('占比')!='-1')?bilv(props.row,i.con):wuyong()" :placeholder="(props.row.label.indexOf('率')!='-1'||props.row.label.indexOf('占比')!='-1')?'请输入(0-100)数字':'请输入'" v-if="props.row[i.con]!=undefined&&i.con!='label'" type="text">
                        <span v-if="props.row[i.con]!=undefined&&i.con!='label'&&(props.row.label.indexOf('率')!='-1'||props.row.label.indexOf('占比')!='-1')" class="baifen_hao">%</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { query_role_board_rule, insert_role_board } from '../../api/api.js'
export default {
  name: 'anasalekanban',
  data () {
    return {
      biaotou: [
        {
          name: '指标',
          con: 'label'
        },
        {
          name: '日',
          con: 'r'
        },
        {
          name: '周',
          con: 'zhou'
        },
        {
          name: '月',
          con: 'yue'
        },
        {
          name: '季',
          con: 'ji'
        },
        {
          name: '年',
          con: 'nian'
        }
      ],
      neirong_list: [
        {
          label: '客户新增数量',
          r: ''
        },
        {
          label: '客户已分配数量',
          r: ''
        },
        {
          label: '拜访次数',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: ''
        },
        {
          label: '单次拜访时长',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: ''
        },
        {
          label: '漏斗金额',
          r: ''
        },
        {
          label: '漏斗折算比率',
          r: ''
        },
        {
          label: '漏斗转化数量',
          r: ''
        },
        {
          label: '漏斗转化金额',
          r: ''
        },
        {
          label: '合同完成率',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        },
        {
          label: '发票完成率',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        },
        {
          label: '未开票比率',
          r: '',
          bitian: true
        },
        {
          label: '回款完成率',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        },
        {
          label: '应收占比',
          r: '',
          bitian: true
        },
        {
          label: '派工单数量',
          r: '',
          zhou: '',
          yue: '',
          ji: '',
          nian: '',
          bitian: true
        }
      ]
    }
  },
  mounted () {
  },
  created () {
    this.jichu()
  },
  watch: {
  },
  methods: {
    jichu () {
      query_role_board_rule({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body != null) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date != null) {
              this.neirong_list[0].r = date.num_cust_add != undefined ? date.num_cust_add : ''
              this.neirong_list[1].r = date.num_cust_alloted != undefined ? date.num_cust_alloted : ''
              this.neirong_list[4].r = date.amt_funn != undefined ? date.amt_funn : ''
              this.neirong_list[5].r = date.rate_funn_disc != undefined ? date.rate_funn_disc : ''
              this.neirong_list[6].r = date.num_funn_translate != undefined ? date.num_funn_translate : ''
              this.neirong_list[7].r = date.amt_funn_translate != undefined ? date.amt_funn_translate : ''
              this.neirong_list[10].r = date.rate_un_inv != undefined ? date.rate_un_inv : ''
              this.neirong_list[12].r = date.rate_un_rec != undefined ? date.rate_un_rec : ''
              for (let i = 1; i < this.biaotou.length; i++) {
                if (date.num_visit != undefined) {
                  this.neirong_list[2][this.biaotou[i].con] = date.num_visit.split(',')[i - 1]
                }
                if (date.single_visit_long != undefined) {
                  this.neirong_list[3][this.biaotou[i].con] = date.single_visit_long.split(',')[i - 1]
                }
                if (date.rate_con_complete != undefined) {
                  this.neirong_list[8][this.biaotou[i].con] = date.rate_con_complete.split(',')[i - 1]
                }
                if (date.rate_inv_complete != undefined) {
                  this.neirong_list[9][this.biaotou[i].con] = date.rate_inv_complete.split(',')[i - 1]
                }
                if (date.rate_rec_complete != undefined) {
                  this.neirong_list[11][this.biaotou[i].con] = date.rate_rec_complete.split(',')[i - 1]
                }
                if (date.num_dispatch != undefined) {
                  this.neirong_list[13][this.biaotou[i].con] = date.num_dispatch.split(',')[i - 1]
                }
              }
            }
          }
        } else if (res.data.code == 500) {}
      })
    },
    bilv (i, key) {
      if (i[key].length != 0) {
        if (Number(i[key]) <= 0) {
          i[key] = 0.01
        }
        if (Number(i[key]) >= 100) {
          i[key] = 100
        }
      }
    },
    wuyong () {},
    dianji_baocun () {
      // 8-13必填
      const cuowu = []
      for (let a = 8; a < this.neirong_list.length; a++) {
        for (let i = 1; i < this.biaotou.length; i++) {
          if (this.neirong_list[a][this.biaotou[i].con] != undefined) {
            if (this.neirong_list[a][this.biaotou[i].con].length == 0) {
              cuowu.push('1111')
            }
          }
        }
      }
      console.log(cuowu)
      if (cuowu.length != 0) {
        this.$message({
          message: '请填写完信息再保存',
          type: 'warning'
        })
      } else {
        const baifang_cishu = []
        const danci_baifang_shichang = []
        const hetong_wancheng_lv = []
        const fapiao_wancheng_lv = []
        const huikuan_wancheng_lv = []
        const paigong_dan_shuliang = []
        for (let i = 1; i < this.biaotou.length; i++) {
          if (this.neirong_list[2][this.biaotou[i].con].length != 0) {
            baifang_cishu.push(this.neirong_list[2][this.biaotou[i].con])
          }
          if (this.neirong_list[3][this.biaotou[i].con].length != 0) {
            danci_baifang_shichang.push(this.neirong_list[3][this.biaotou[i].con])
          }
          if (this.neirong_list[8][this.biaotou[i].con].length != 0) {
            hetong_wancheng_lv.push(this.neirong_list[8][this.biaotou[i].con])
          }
          if (this.neirong_list[9][this.biaotou[i].con].length != 0) {
            fapiao_wancheng_lv.push(this.neirong_list[9][this.biaotou[i].con])
          }
          if (this.neirong_list[11][this.biaotou[i].con].length != 0) {
            huikuan_wancheng_lv.push(this.neirong_list[11][this.biaotou[i].con])
          }
          if (this.neirong_list[13][this.biaotou[i].con].length != 0) {
            paigong_dan_shuliang.push(this.neirong_list[13][this.biaotou[i].con])
          }
        }
                
        insert_role_board({
          data: {
            ent_id: this.$ent_id(),
            num_cust_add: this.neirong_list[0].r.length != 0 ? this.neirong_list[0].r + '' : null,
            num_cust_alloted: this.neirong_list[1].r.length != 0 ? this.neirong_list[1].r + '' : null,
            num_visit: baifang_cishu.length == 5 ? baifang_cishu.map(item => item).join(',') : null,
            single_visit_long: danci_baifang_shichang.length == 5 ? danci_baifang_shichang.map(item => item).join(',') : null,
            amt_funn: this.neirong_list[4].r.length != 0 ? this.neirong_list[4].r + '' : null,
            rate_funn_disc: this.neirong_list[5].r.length != 0 ? this.neirong_list[5].r + '' : null,
            num_funn_translate: this.neirong_list[6].r.length != 0 ? this.neirong_list[6].r + '' : null,
            amt_funn_translate: this.neirong_list[7].r.length != 0 ? this.neirong_list[7].r + '' : null,
            rate_con_complete: hetong_wancheng_lv.length == 5 ? hetong_wancheng_lv.map(item => item).join(',') : null,
            rate_inv_complete: fapiao_wancheng_lv.length == 5 ? fapiao_wancheng_lv.map(item => item).join(',') : null,
            rate_un_inv: this.neirong_list[10].r.length != 0 ? this.neirong_list[10].r + '' : null,
            rate_rec_complete: huikuan_wancheng_lv.length == 5 ? huikuan_wancheng_lv.map(item => item).join(',') : null,
            rate_un_rec: this.neirong_list[12].r.length != 0 ? this.neirong_list[12].r + '' : null,
            num_dispatch: paigong_dan_shuliang.length == 5 ? paigong_dan_shuliang.map(item => item).join(',') : null
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
      }
    },
    liebiao_yangshi (row) {
      console.log(row)
      if (row.column.label == '指标') {
        return 'font-size:0.12rem;text-align:center;backround:#ccc;'
      }
    },
    biaotou_yangshi () {
      return 'font-size:0.12rem;text-align:center;backround:#ccc;'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './quanxian.scss';
</style>
